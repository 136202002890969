import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BigHeader from "../../components/bigHeader"
import { StaticQuery, graphql } from "gatsby"

const RaisedInTheWild = ({ contentField }) => {
  const lang = "it"
  const seo = contentField.seo
  const image = contentField.featuredImage
  return (
    <Layout
      lang={lang}
      translationEN="/raised-in-the-wild/"
      translationDE="/de/im-offenen-meer-aufgezogen/"
      translationES="/es/criado-en-la-naturaleza/"
      translationFR="/fr/eleve-en-pleine-mer/"
    >
      <SEO
        title={seo.title}
        description={seo.metaDesc}
        image={image ? image.sourceUrl : "https://hiddenfjord.com/og-image.png"}
      />

      <BigHeader
        title="ALLEVATO NEL SUO AMBIENTE NATURALE"
        image={require("../../assets/images/raised-in-the-wild-background.jpg")}
      />

      <div className="main-section">
        <div
          className="text-container"
          dangerouslySetInnerHTML={{
            __html: contentField.content,
          }}
        />
      </div>
    </Layout>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentField: wpPage(id: { eq: "cG9zdDoxNjY0" }) {
          content
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    `}
    render={data => <RaisedInTheWild {...data} />}
  />
)
